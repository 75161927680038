<template>
    <div>
        <b-row>
            <b-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
                class="d-flex mb-1"
                style="cursor: pointer"
                @click="$router.push({ name: 'reports-expense' })"
            >
                <feather-icon
                    style="margin-top: 3px; padding-left: 0px; margin-left: 0px"
                    icon="ArrowLeftIcon"
                    size="1x"
                />
                <div style="margin-left: 5px">Back</div>
            </b-col>
        </b-row>
        <b-card>
            <b-row>
                <b-col>
                    <expense-report-items-table />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BRow, BCol, BCard, VBTooltip } from "bootstrap-vue"
import ExpenseReportItemsTable from "@/components/ExpenseReportItemsTable.vue"

export default {
    name: "ExpenseReportItems",
    components: {
        ExpenseReportItemsTable,
        BRow,
        BCol,
        BCard,
        VBTooltip,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
