<template>
    <div>
        <div class="custom-search d-flex">
            <div class="d-flex">
                <h4>People's expense report</h4>
            </div>
            <div class="mr-auto ml-1">
                <b-badge style="font-size: 13px" variant="light-dark">
                    {{ getMonthName(this.$route.params.date) }}
                </b-badge>
            </div>
            <div class="d-flex align-items-center">
                <b-form-group>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </b-form-group>
            </div>
        </div>
        <vue-good-table
            v-if="rows.length > 0"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <!-- <span
          v-b-tooltip.hover.right
          title="Click to check report!"
          v-if="props.column.field === 'date'" @click="$router.push('/reports/expense/entries')" style="cursor: pointer;">
          {{ props.row.date }}
        </span> -->
                <span v-if="props.column.field === 'expense.attachment'">
                    <b-avatar
                        v-if="props.row.expense.attachment"
                        variant="light-secondary"
                    >
                        <b-button
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            @click="openPreview(props.row.expense.attachment)"
                        >
                            <feather-icon
                                v-b-tooltip.hover.top="'View expense'"
                                icon="EyeIcon"
                                size="16"
                            />
                        </b-button>
                    </b-avatar>
                    <b-avatar
                        v-if="props.row.expense.attachment"
                        variant="light-secondary"
                        class="ml-50"
                    >
                        <b-button
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            @click="
                                downloadPreview(
                                    '/' +
                                        props.row.expense.attachment
                                            .split('/')
                                            .slice(3, 10)
                                            .join('/')
                                )
                            "
                        >
                            <feather-icon
                                v-b-tooltip.hover.top="'Download expense'"
                                icon="DownloadIcon"
                                size="16"
                            />
                        </b-button>
                    </b-avatar>
                </span>
                <span
                    v-else-if="props.column.field === 'expense.employee.name'"
                >
                    <b-avatar
                        v-if="props.row.expense.employee"
                        class="mr-right-10"
                        size="36px"
                        :src="props.row.expense.employee.avatar"
                    />
                    {{ props.row.expense.employee.name }}
                </span>
                <span v-else-if="props.column.field === 'expense.status'">
                    <b-badge
                        v-if="props.row.status"
                        :variant="statusVariant(props.row.status.description)"
                    >
                        {{ props.row.status.description }}
                    </b-badge>
                </span>
                <span v-else-if="props.column.field === 'expense.bob'">
                    <span v-if="props.row.expense.bob === true">
                        <b-badge variant="light-success"> Yes </b-badge>
                    </span>
                    <span v-else>
                        <b-badge variant="light-danger"> No </b-badge>
                    </span>
                </span>
                <span v-else-if="props.column.field === 'expense.one_drive'">
                    <span v-if="props.row.expense.one_drive === true">
                        <b-badge variant="light-success"> Yes </b-badge>
                    </span>
                    <span v-else>
                        <b-badge variant="light-danger"> No </b-badge>
                    </span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="mt-43">Total: {{ rows.length }}</div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="
                                (value) =>
                                    props.pageChanged({ currentPage: value })
                            "
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <div v-else>
            <b-alert variant="secondary" show>
                <div class="alert-body mt-1" style="margin-bottom: -14px">
                    <span><strong>Sorry!</strong> No results found.</span>
                </div>
            </b-alert>
        </div>
    </div>
</template>

<script>
import {
    BPagination,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    BAvatar,
    BCardText,
    BBadge,
    BAlert,
} from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import store from "@/store/index"
import Expenses from "@/api/services/Expenses"
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
    name: "ExpenseReportItemsTable",
    components: {
        VueGoodTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BButton,
        BCard,
        ToastificationContent,
        VBTooltip,
        BTooltip,
        BAvatar,
        BCardText,
        BBadge,
        BAlert,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            pageLength: 100,
            dir: false,
            columns: [
                {
                    label: "Expense",
                    field: "expense.attachment",
                    tdClass: "height-50",
                },
                {
                    label: "Employee",
                    field: "expense.employee.name",
                },
                {
                    label: "Total",
                    field: "expense.amount",
                    type: "decimal",
                    formatFn: this.formatEuro,
                },
                {
                    label: "Previous status",
                    field: "expense.status",
                },
                {
                    label: "Bob",
                    field: "expense.bob",
                },
                {
                    label: "Onedrive",
                    field: "expense.one_drive",
                },
            ],
            rows: [],
            searchTerm: "",
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        getMonthName(dateString) {
            const date = new Date(dateString)
            const monthNumber = date.getMonth()
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ]
            return monthNames[monthNumber]
        },
        downloadPreview(link) {
            this.$loading(true)
            const name = link.split("/").slice(-1)
            Expenses.getFile(link).then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", name)
                document.body.appendChild(link)
                link.click()
                setTimeout(() => {
                    this.$loading(false)
                }, 1000)
            })
        },
        openPreview(link) {
            window.open(link, "_blank")
        },
        loadData() {
            this.$loading(true)
            this.rows = []
            Expenses.getExpenseReportItems(this.$route.params.date).then(
                (response) => {
                    this.rows = response.data.results
                    this.$loading(false)
                }
            )
        },
        formatEuro(value) {
            return `${value.toFixed(2)} €`
        },
        statusVariant(status) {
            switch (status) {
                case "Open":
                    return "light-info"
                case "Pending approval":
                    return "light-warning"
                case "Approved":
                    return "light-success"
                case "Declined":
                    return "light-danger"
                case "Closed":
                    return "light-dark"
                case "Pending submission":
                    return "light-secondary"
                case "Pending changes":
                    return "light-secondary"
                default:
                    return "light-primary"
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
